<template>
  <component :is="tag" class="asset-with-background">
    <div class="asset section-inner section-contained">
      <StoryblokComponent
        v-if="blok.asset?.length === 1"
        :blok="blok.asset[0]"
      />
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const background = computed(() => {
  return props.blok.background_color;
});
</script>

<style lang="scss" scoped>
.asset-with-background {
  &.section {
    align-items: center;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: v-bind(background);
  }

  .asset {
    :deep(img),
    :deep(video),
    :deep(.youtube-player) {
      border-radius: $radius-m;

      @include for-desktop-up {
        border-radius: $radius-l;
      }

      @include for-desktop-down {
        aspect-ratio: 1;
      }
    }

    :deep(img) {
      width: 100%;
      display: block;
    }
  }
}
</style>
